.main{
  background-color: #191919;
}

.map{
    display: flex;
    flex-direction: column;
    /* min-height: 500px;
    max-width: 100%; */
    padding-top: 65px;
    padding-top: 115px;
    padding-bottom: 55px;
}

.map__title{
    color: white;
    margin: 0px;
    font-size: 24px;
}

.map__content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.map__svg-container{
  display: inline-block;
  position: relative;
  width: 954px;
  height: 500px;
  margin-left: 150px;
  resize: both;
  object-fit: cover;
}

.map__svg{
  position:absolute;
  /* width: 954px;
  height: 600px; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  object-fit: fill;
    z-index: 0;
    /* margin-left: 60px; */
}

.map__tooltip {
  position: absolute;
  /* right: -100px; */
  text-align: center;
  /* min-width: 100px; */
  width: 200px;
  height: min-content;
  padding: 2px;
  font: 14px sans-serif;
  font-family: 'Gilroy';
  font-weight: bold;
  background: white;
  border: 2px solid white;
  border-radius: 8px;
  pointer-events: none;
  /* overflow: hidden; */
}

.map__explanation{
  display:none;
}


.map__title{
    color: white;
    text-align: center;
}


.map__legend{
    fill: white;
    font-size: 12px;
}

.map__panel{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 450px;
    margin-left: 30px;
    border: 1px solid white;
    border-radius: 12px;
    width: 400px;
    padding-bottom: 50px;
    /* background-color: gray; */
}

.map__buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 40px;
    width: 100%;
    /* padding-bottom: 40px;
    border-bottom: white 5px solid; */
    /* padding-left: 30px; */
}

.map__metrics{
    cursor: pointer;
    background-color: inherit;
    color: white;
    border: none;
    margin-bottom: 10px;
    /* border: 1px white dotted; */
}

.map__dropdown{
    position: relative;
    z-index: 3;
    margin:0;
}

.map__dropdown-article-button{
    cursor: pointer;
    background-color: inherit;
    width: 100%;
    height: 25px;
    color: white;
    border: none;
    margin-bottom: 10px;
    border: 1px white solid;
    border-radius: 12px;
    min-width: 70px;
}

.map__dropdown-article-button:before {
  position: absolute;
  content: " ";
  height: 7px;
  width: 7px;
  border: 2px solid white;
  border-left-width: 0px;
  border-top-width: 0px;
  top: 5px;
  right: 10px;
  transform: rotate(45deg);
  z-index: 2;
}


.map__dropdown-article-button:hover{
  opacity: 0.7;
}

.map__dropdown-list{
    position: absolute;
    list-style-type: none;
    margin-top:  0;
    padding: 0;
    border: 1px solid grey;
    overflow:auto;
  }


.map__dropdown-list_items{
    width: 140px;
    max-height:220px;
}

.map__dropdown-list_metrics{
    width: 220px;
    max-height:220px;
}

.map__dropdown-list_years{
  width: 100%;
  max-width: 150px;
  max-height:220px; 
}

.map__dropdown-text{
    font-family: inherit;
    font-size: 12px;
}

.map__dropdown-button {
    width: 100%;
    height: 60%;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    /* padding: 5px; */
    margin: 0;
    padding: 0;
    font: inherit;
    cursor: pointer;
    background-color: white;
}

  .map__dropdown-button:hover {
    background-color: lightgray;
  }

  .map__dropdown-button_main{
    font-weight: bold;
  }

  .map__federal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .map__center-link{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: black; 
    font-weight: bold;
  }

  .map__square{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 120px;
    height: 120px;
    background-color: #fff;
    /* border: 1px solid darkgreen; */
  }
  .map__square:hover {
    background-color: lightgray;
  }

  .map__square-text{ 
    text-align: center;
    color: black;
    align-self: center;
    text-decoration:none;
    color: white;
    padding:0;
    margin:0;
  }
  
  .map__square-value{
    position: relative;
    /* bottom: 80px; */
    margin: 0 auto;
    font-size: 14px;
  }

  .map__arrow{
    display: none;
  }

  @media screen and (max-width: 768px) {
    .map__content{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
  }

  .map__svg-container{
    width: 700px;
    height: 500px;
    margin-left: 50px;
  }

    .map__panel{
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-height: 0;
      margin-left: 0;
      border: none;
      width: 100%;
      padding-bottom: 0;
  }

  .map__buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
  }

  .map__tooltip {
  width: 140px;
  }
  .map__explanation{
    position: absolute;
    top: 180px;
    left: 50;
    display:block;
    color: white;
    font-size: 12px;
    font-weight: bold;
    white-space: pre-wrap;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .map__dropdown_center{
    margin: 0 15px;
  }
  /* .map__square{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    width: 60px;
    height: 60px;
    background-color: #fff;
  } */

  .map__square-text{
    font-size: 12px;
  }

  .map__federal{
    position: absolute; 
    left: 50;
    top: 220px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
  }
  
  .map__square{
    flex-direction: row;
    width: 110px;
    height: 40px;
    background-color: #fff;
    /* border: 1px solid darkgreen; */
  }
}

@media screen and (max-width: 500px) {
.map__svg-container{
  width: 400px;
  height: 900px;
  margin-left: 20px;
}

.map__content{
  position: relative;
}

.map__federal{
  position: absolute;
  justify-content: flex-end;
  align-items: flex-end;
  right: 0;
  margin-right: 20px;
  max-width: 100%;
  top: 235px;
}

.map__square{
  flex-direction: row;
  width: 130px;
  height: 15px;
  background-color: #fff;
  /* border: 1px solid darkgreen; */
}

.map__square-text{
  align-self: flex-end;
  font-size: 12px;
}

.map__square-value{
  font-size: 12px;
}

.map__arrow{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  margin-right: 5px;
  color: white;
}

.map__arrow_europe{
  top: 250px;
  height: 400px;
  width: 0;
  border-left: 1px white solid;
}

.map__arrow_asia{
  top: 676px;
  height: 250px;
  width: 0;
  border-left: 1px white solid;
}

.map__arrow-paragraph{
  margin: 0 ;
  background-color: #191919; 
}
}

@media screen and (max-width: 400px) {
  .map__svg-container{
    width: 350px;
    height: 900px;
  }
  .map__legend{
    font-size: 11px;
}

  .map__arrow_europe{
    top: 290px;
    height: 370px;
  }

  .map__arrow_asia{
    top: 690px;
    height: 230px;
  }
}

@media screen and (max-width: 350px) {
  .map__svg-container{
    width: 300px;
    margin-left: 0;
  }

  .map__explanation{
    font-size: 10px;
    }

    .map__legend{
      font-size: 10px;
    }

    .map__dropdown-article-button{
      min-width: 100%;
  }
}