.regions{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #191919;
  padding-top: 100px;
  /* grid-template-areas: 
  "a a a"
  "b b b"
  "c d e";
  grid-template-columns: 250px;
  gap: 10px; */
  /* border: 1px dotted white; */
  padding-bottom: 0px;
}

.regions__title{
  /* grid-area: a; */
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: 1px dotted white;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-align: regions;
  color: white;
}

.regions__explanation{
  display:none;
}

.regions__loading{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: white;
  height: 550px;
  margin-right: 50px;
  font-size: 32px;
}

 .regions__value{
  position: relative;
  color: white;
  font-size: 16px;
  font-style: bold;
  margin: 0;
  /* margin-left: 53px; */
  padding-bottom: 10px;
  width: 100%;
  /* border-left:  solid 1px white; */
  border-right:  solid 1px white;
}

.regions__treemap{
  /* grid-area: d; */
  margin: 0;
  margin-right: 40px;
  width: 70vw;
}

.regions__list{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 500px
}

.regions__list_year{
 margin-top: 20px;
}

/* .regions__space{
  grid-area: e;
} */

.regions__navigation{
  color: white;
}

.regions__test{
  color: white;
  text-align: center;
  margin-left: 0;
  padding-left: 0;
  width: 412px;
}

.regions__map{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  /* border-left:  solid 1px white; */
  border-right:  solid 1px white;
}
/* 
.regions__search{
  margin-bottom: ;
} */
@media screen and (max-width: 1150px) {
  .regions__test{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 350px
  }
  
  .regions__list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 400px
  }

  .regions__value{
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .regions{
    flex-direction: column;
    padding-top: 60px;
  }
  .regions__treemap{
    /* grid-area: d; */
    margin: 0 auto;
    /* padding: 0 5vw; */
    /* width: 90vw; */
    width:fit-content;
    margin-top: 20px;
  }
  .regions__list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
}
  .regions__test{
    display: grid;
    grid-template-columns: repeat(auto-fill, 33% 33% 33%);
    width: 100%;
    margin: 0 auto;
  }
  
  .regions__explanation{
    display:block;
    color: white;
    font-size: 12px;
    font-weight: bold;
    white-space: pre-wrap;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .regions__loading{
    height: 500px;
  }

  
  .regions__value{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: none;
    border-bottom: 1px solid white;
    /* width: 16vw; */
    font-size: 12px;
    font-weight: bold;
    min-height: 70px;
    padding: 0;
  }

  .regions__map{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin:0;
    /* width: 16vw; */
    padding: 0;
    border-right: none;
    border-bottom: 1px solid white;
  }

  .regions__arrow {
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent; 
    border-bottom: 5px solid transparent; /* 40px height (20+20) */
    border-left: 5px solid white;
    position:absolute;
    left:-5px;
    bottom:-5px;
  }
  

  .regions__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    /* width: 18vw; */
    margin: 0;
    padding: 0;
    min-height: 70px;
  }
  
  .regions__span{
    display: none;
  }
} 


@media screen and (max-width: 450px) {
  .regions__loading{
    height: 400px;
  }
}


@media screen and (max-width: 345px) {
  .regions__explanation{
  font-size: 10px;
  }
}