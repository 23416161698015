/* @import url(./vendor/normalize.css); */
@import url(./vendor/fonts/fonts.css);


body {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Gilroy', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
html {
  scrollbar-width: none;
}

/* Hide scrollbar for IE and Edge */
html {
  -ms-overflow-style: none;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
