.footer {
    /* font-family: 'Roboto'; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    background-color: #000000;
    height: 65px;
    border-top: white 2px solid;
    /* border-top: 1px dotted white; */
    /* padding-top: 55px; */
  } 

  .footer__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    padding: 12px 0;
  } 

  .footer__text{
    color: white;
    text-decoration: none;
    font-size: 12px;
    /* margin-left: 10px; */
    /* padding: 12px 48px; */
  }

  .footer__logo{
    width: 25px;
    height: 25px;  
    cursor: pointer; 
  }