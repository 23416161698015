  @font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("Gilroy-Medium.woff") format("woff")
  }
  
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("Gilroy-Bold.woff") format("woff")
  }
  