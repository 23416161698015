.header{
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    width: 90vw;
    padding: 0 5vw;
    height: 75px;
    /* height: calc(var(--vh, 1vh) * 10); */
    z-index: 4;
    border-bottom: white 2px solid;
}


.header__logo{
    height: 65px;
    margin-right: 20px;
    margin: 5px auto;
}

.header__main{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    /* border: white 1px solid; */
}

.header__link{
    color: white;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}

.header__link_active{
    border: none;
    border-bottom: 2px solid white;
  }

.header__link_button{
    position: relative;
    text-justify: start;
    text-decoration: none;
    text-align: start;
    background-color: black;
    padding: 0;
    margin:0;
    font-family: Montserrat;
    line-height: 18px;
    /* border-bottom: 1px solid #001F3D; */
}

.header__links-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
}

.header__links-column{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 10vh;
    /* height: calc(var(--vh, 1vh) * 10); */
    height:90vh;
    /* height: calc(var(--vh, 1vh) * 90); */
    left:0;
    transform: translateY(0%);
    /* visibility: visible; */
    overflow: auto;
    z-index: 2;
    width: 100vw;
    background-color: #0000004d;
}

.header__links-column-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* padding: 22px 22px 90px; */
    background-color: black;
    width:100vw;
    /* max-width: 520px;
    width: 100%; */
    min-height: 80vh;
    /* min-height: calc(var(--vh, 1vh) * 80); */
    padding-top: 30px;
    /* row-gap: 105px; */
}

.header__burger-menu{
    display: none;
}

.header__burger-close-button{
    display: none;
}

@media screen and (max-width: 1330px) {
    .header{
        width: 90vw;
        padding: 0 5vw;
    }

    .header__links-row{
        width: 60%;
    }
}

@media screen and (max-width: 1200px) {
    .header{
        width: 93vw;
        padding: 0 2vw 0 5vw;
    }

    .header__links-row{
        width: 65%;
    }
}

@media screen and (max-width: 1000px) {
.header{
    height: 65px;
    /* height: calc(var(--vh, 1vh) * 10); */
}

.header__logo{
    height: 65px;
    /* height: calc(var(--vh, 1vh) * 10); */
}
.header__links-row {
    display: none;
}
.header__links-column{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 65px;
    /* top: calc(var(--vh, 1vh) * 10); */
    height:90vh;
    /* height: calc(var(--vh, 1vh) * 90); */
}

.header__burger-menu{
    display: block;
    border: none;
    width: 28px;
    height: 23px;
    /* height: calc(var(--vh, 1vh) * 6);    */
    background-image: url("../../images/burger.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: black;
}

.header__burger-close-button{
    display: block;
    border: none;
    width: 28px;
    height: 23px;
    background-image: url("../../images/close-button.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: black;
}

.header__links-column-container{
    height: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    -ms-verrflow-style: none;
    overflow: - moz-scrollbars-none;
    scrollbar-width: none;
    -ms-overflow-style: none; 
    /* min-height: calc(var(--vh, 1vh) * 80); */
}
}
