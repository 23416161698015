.navigation__link{
    color: white;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}

.navigation__link_active{
    border: none;
    border-bottom: 2px solid white;
  }


.navigation__link:hover {
    color: lightgray;
}

.navigation__link_button{
    position: relative;
    text-justify: start;
    text-decoration: none;
    text-align: start;
    background-color: black;
    padding: 0;
    margin:0;
    font-family: Montserrat;
    line-height: 18px;
    /* border-bottom: 1px solid #001F3D; */
}
.navigation__link_inactive{
    border: none;
}

.navigation__button-dropped:before {
    position: absolute;
    content: " ";
    height: 7px;
    width: 7px;
    border: 3px solid black;
    border-left-width: 0px;
    border-top-width: 0px;
    bottom: 30%;
    right: 0px;
    transform: rotate(45deg);
    z-index: 2;
  }

  .navigation__button-undropped:before {
    position: absolute;
    content: " ";
    height: 7px;
    width: 7px;
    border: 3px solid lightgray;
        /* background-color: #001F3D; */
    border-right-width: 0px;
    border-bottom-width: 0px;
    bottom: 20%;
    right: 0px;
    transform: rotate(45deg);
    z-index: 2;
  }

.navigation__dropdown{
    position: absolute;
    width: 100%;
    top: 80%;
    left: 0%;
}  

.navigation__dropdown-list{
    text-decoration: none;
    background-color: #fff;
    padding:0;
    border-bottom: 2px solid white;
    /* border-right: 2px solid #001F3D;
    border-left: 2px solid #001F3D; */
}

.navigation__dropdown-item{
    background-color: white;
    color: 001F3D;
    margin: 10px auto;
    list-style-type: none;
    width: 100%;
}

.navigation__dropdown-button{
    text-decoration: none;
    background-color: inherit;
    font-size: 12px;
    color: white;
    border: none;
    font-family: Montserrat;
}

@media screen and (max-width: 1150px) {
    .navigation__link{
    font-size: 14px;
    }
}
@media screen and (max-width: 1000px) {
    .navigation__dropdown {
        position: unset;
    }
    .navigation__link_button::before{
        position: absolute;
        top: 5%;
        bottom: unset;
        right: 10px;
    }
    .navigation__dropdown-list{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: none;
        border-left:2px solid white;
        padding: 0;
        margin-top: 30px;
        margin-bottom: 0px;
        height: 18vh
}
    .navigation__link{
        padding-bottom: 30px;
        width: 90vw;
        text-align: start;
        margin: 15px 5vw;
        font-size: 14px;
    }
    .navigation__link_button{
        padding-left: 0;
        text-align: start;
    }

    .navigation__dropdown-item{
        padding:0;
        margin:0;
    }

    .navigation__link_main{
        border-bottom: 1px solid white;
    }
    .navigation__link:hover {
        color: black;
    }

    .navigation__link_active{
        color: lightgray;
      }
    
      .navigation__button-undropped:before {
        position: absolute;
        content: " ";
        height: 7px;
        width: 7px;
        border: 3px solid black;
            /* background-color: #001F3D; */
        border-right-width: 0px;
        border-bottom-width: 0px;
        bottom: 30%;
        right: 10px;
        transform: rotate(45deg);
        z-index: 2;
      }
}