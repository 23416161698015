.not-found{
    padding-top: 65px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-found__title{
    font-size: 120px;
    color: white;
}

.not-found__text{
    color: white;
}

.not-found__button{
    color: white;
    text-decoration: none;
}
.not-found__button{
    border: none;
    cursor: pointer;
    background-color: white;
    font-family: inherit;
    border-radius: 30px;
    font-size: 16px;
    width: 200px;
    height: 5vh;
    margin-bottom: 10vh;
    font-weight: bold;
    color: black;
    border:black 2px solid;
}

.not-found__button:hover{
    background-color: lightgray;
    color: black;
    border: none;   
}