.extra{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 100px;
    background-color: #191919;
    gap: 10px 2%;
  }
  
  .extra__title{
    /* grid-area: a; */
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: 1px dotted white;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: extra;
    color: white;
  }

  .extra__explanation{
    display:none;
  }
  
  .extra__loading{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: white;
    height: 550px;
    margin-right: 50px;
    font-size: 32px;
  }
  
   .extra__value{
    position: relative;
    color: white;
    font-size: 16px;
    font-style: bold;
    margin: 0;
    /* margin-left: 53px; */
    padding-bottom: 10px;
    width: 100%;
    /* border-left:  solid 1px white; */
    border-right:  solid 1px white;
  }
  
  .extra__treemap{
    /* grid-area: d; */
    margin: 0;
    margin-right: 40px;
    width: 70vw;
  }
  
  .extra__list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  
  .extra__list_year{
   margin-top: 20px;
  }
  
  /* .extra__space{
    grid-area: e;
  } */
  
  .extra__navigation{
    color: white;
  }
  
  .extra__test{
    color: white;
    text-align: center;
    margin-left: 0;
    padding-left: 0;
    width: 412px;
  }
  
  .extra__map{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    /* border-left:  solid 1px white; */
    border-right:  solid 1px white;
  }
  /* 
  .extra__search{
    margin-bottom: ;
  } */

  .extra__buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 10%;
    /* margin-top: 30px; */
    margin-left: 40px;
    /* margin-bottom: 20px; */
  }

  .extra__dropdown{
    position: relative;
    margin: 0;
    z-index: 3;
    margin-right: 10px;
}

.extra__dropdown-list{
    position: absolute;
    list-style-type: none;
    margin-top:  0;
    padding: 0;
    border: 1px solid grey;
    overflow:auto;
  }

.extra__dropdown-article-button{
    cursor: pointer;
    background-color: inherit;
    width: 80px;
    height: 25px;
    color: white;
    border: none;
    margin-bottom: 10px;
    border: 1px white solid;
    border-radius: 12px;
}

.extra__dropdown-article-button:before {
  position: absolute;
  content: " ";
  height: 7px;
  width: 7px;
  border: 2px solid white;
  border-left-width: 0px;
  border-top-width: 0px;
  top: 18%;
  right: 10%;
  transform: rotate(45deg);
  z-index: 2;
}

.extra__dropdown-article-button:hover{
  opacity: 0.7;
}

.extra__dropdown-list_items{
    width: 140px;
    max-height:220px;
}

.extra__dropdown-list_metrics{
    width: 220px;
    max-height:220px;
}

.extra__dropdown-list_years{
  width: 100%;
  max-width: 150px;
  max-height:220px; 
}

.extra__dropdown-text{
    font-family: inherit;
    font-size: 12px;
}

.extra__dropdown-button {
    width: 100%;
    height: 60%;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    /* padding: 5px; */
    margin: 0;
    padding: 0;
    font: inherit;
    cursor: pointer;
    background-color: white;
}

  .extra__dropdown-button:hover {
    background-color: lightgray;
  }

  .extra__dropdown-button_main{
    font-weight: bold;
  }

  .extra__federal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .extra__center-link{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: black; 
    font-weight: bold;
  }
  
  @media screen and (max-width: 1150px) {
    .extra__test{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 350px
    }
    
    .extra__list{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 400px
    }
  
    .extra__value{
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    .extra{
      flex-direction: column;
      padding-top: 60px;
    }
    
    .extra__list{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100vw;
  }
    .extra__test{
      display: grid;
      grid-template-columns: repeat(auto-fill, 33% 33% 33%);
      width: 100%;
      margin: 0 auto;
    }

    .extra__explanation{
      display:block;
      color: white;
      font-size: 12px;
      font-weight: bold;
      white-space: pre-wrap;
      text-align: center;
      padding: 0;
      margin: 0;
    }
  
    .extra__loading{
      height: 500px;
    }
  
    
    .extra__value{
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: none;
      border-bottom: 1px solid white;
      /* width: 16vw; */
      font-size: 12px;
      font-weight: bold;
      min-height: 70px;
      padding: 0;
    }
  
    .extra__map{
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin:0;
      /* width: 16vw; */
      padding: 0;
      border-right: none;
      border-bottom: 1px solid white;
    }
  
    .extra__arrow {
      width: 0; 
      height: 0; 
      border-top: 5px solid transparent; 
      border-bottom: 5px solid transparent; /* 40px height (20+20) */
      border-left: 5px solid white;
      position:absolute;
      left:-5px;
      bottom:-5px;
    }
    
    .extra__treemap{
      /* grid-area: d; */
      padding: 0 5vw;
      width: 90vw;
      margin-top: 20px;
    }
  
    .extra__text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      /* width: 18vw; */
      margin: 0;
      padding: 0;
      min-height: 70px;
    }
    
    .extra__span{
      display: none;
    }
  } 
  
  
  @media screen and (max-width: 450px) {
    .extra__loading{
      height: 400px;
    }
    
    .extra__buttons{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 70%;
      margin: 30px auto 20px 
    }
    .extra__dropdown{
      margin-right: 0;
    }
  }
  
  
  @media screen and (max-width: 345px) {
    .extra__explanation{
    font-size: 10px;
    }
  }