.center{
    display: flex;
    flex-direction: row;
    /* grid-template-areas: 
    "a a a"
    "b b b"
    "c d e"; */
    /* grid-template-rows: min-content 300px; */
    gap: 10px 2%;
    /* border: 1px dotted white; */
    padding-bottom: 0px;
    background-color: #191919;
    padding-top: 100px;
    padding-bottom: 60px;
 }
  
  .center__title{
    grid-area: a;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: 1px dotted white;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: white;
  }


.center__dropdown{
  padding-left: 70px;
}

.center__dropdown-year{
    position: relative;
    z-index: 3;
    margin:0;
    padding-left: 70px;
}

.center__dropdown-article-button{
    position: relative;
    cursor: pointer;
    background-color: inherit;
    width: 70px;
    height: 25px;
    color: white;
    border: none;
    margin-bottom: 10px;
    border: 1px white solid;
    border-radius: 12px;
    min-width: 30px;
}

.center__dropdown-article-button:before {
  position: absolute;
  content: " ";
  height: 7px;
  width: 7px;
  border: 2px solid white;
  border-left-width: 0px;
  border-top-width: 0px;
  top: 5px;
  right: 10px;
  transform: rotate(45deg);
  z-index: 2;
}


.center__dropdown-article-button:hover{
  opacity: 0.7;
}

.center__dropdown-list{
    position: absolute;
    list-style-type: none;
    margin-top:  0;
    padding: 0;
    border: 1px solid grey;
    overflow:auto;
    z-index: 2;
  }


.center__dropdown-list_items{
    width: 70px;
    max-height:220px;
}

.center__dropdown-list_metrics{
    width: 220px;
    max-height:220px;
}

.center__dropdown-list_years{
  /* width: 100%; */
  max-width: 150px;
  max-height:220px; 
}

.center__dropdown-item{
  width: 70px;
}

.center__dropdown-text{
    font-family: inherit;
    font-size: 12px;
}

.center__dropdown-button {
    width: 100%;
    height: 60%;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    /* padding: 5px; */
    margin: 0;
    padding: 0;
    font: inherit;
    cursor: pointer;
    background-color: white;
}

  .center__dropdown-button:hover {
    background-color: lightgray;
  }

  .center__dropdown-button_main{
    font-weight: bold;
  }

  .center__explanation{
    display:none;
  }

  .center__value{
    color: white;
    font-size: 16px;
    font-style: bold;
    margin: 0;
    margin-left: 53px;
    padding-bottom: 10px;
  }
  
  .center__treemap{
    grid-area: d;
    margin: 0 auto;
  }

  .center__list{
    grid-area: c;
  }

  .center__loading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;
    height: 550px;
    font-size: 32px;
  }

  /* 
  .center__type{
    grid-area: b;
  } */
  
  .center__panel{
    grid-area: c;
  }

  .center__map{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    /* border-left:  solid 1px white; */
    border-right:  solid 1px white;
  }

  .center__test{
    color: white;
    text-align: center;
    margin-left: 0;
    padding-left: 0;
    width: 412px;
  }

  .center__value{
    position: relative;
    color: white;
    font-size: 16px;
    font-style: bold;
    margin: 0;
    /* margin-left: 53px; */
    padding-bottom: 10px;
    width: 100%;
    /* border-left:  solid 1px white; */
    border-right:  solid 1px white;
  }

  @media screen and (max-width: 1150px) {
    .center__test{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 350px
    }
    
    .center__list{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 400px
    }
  
    .center__value{
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    .center{
      display: flex;
      flex-direction: column;
    }

    .center__list{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100vw;
  }
  
    .center__test{
      display: grid;
      grid-template-columns: repeat(auto-fill, 33% 33% 33%);
      width: 100%;
      margin: 0 auto;
    }

    .center__explanation{
      display:block;
      color: white;
      font-size: 12px;
      font-weight: bold;
      white-space: pre-wrap;
      text-align: center;
      padding: 0;
      margin: 0;
    }
    .center__dropdown{
      padding-left: 10px;
    }
    .center__value{
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: none;
      border-bottom: 1px solid white;
      /* width: 16vw; */
      font-size: 12px;
      font-weight: bold;
      min-height: 70px;
      padding: 0;
    }
  
    .center__map{
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin:0;
      /* width: 16vw; */
      padding: 0;
      border-right: none;
      border-bottom: 1px solid white;
    }

    .center__loading{
      height: 500px;
    }
  
    .center__arrow {
      width: 0; 
      height: 0; 
      border-top: 5px solid transparent; 
      border-bottom: 5px solid transparent; /* 40px height (20+20) */
      border-left: 5px solid white;
      position:absolute;
      left:-5px;
      bottom:-5px;
    }
    
    .center__treemap{
      /* grid-area: d; */
      padding: 0 5vw;
      width: 90vw;
      margin-top: 20px;
    }
  
    .center__text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      /* width: 18vw; */
      margin: 0;
      padding: 0;
      min-height: 70px;
    }
    
    .center__span{
      display: none;
    }
  } 

  @media screen and (max-width: 450px) {
    .center__loading{
      height: 400px;
    }
  }
  

  @media screen and (max-width: 345px) {
    .center__explanation{
    font-size: 10px;
    }
  }